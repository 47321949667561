import * as React from 'react';

import { Image, StyleSheet, Text, View, Linking } from "react-native";
import TouchableScale from 'react-native-touchable-scale';
import * as Schemes from '../constants/ColorSchemes';
import { useNavigation } from '@react-navigation/native';

export default function FullCard( props ) {

  const navigation = useNavigation();

  function renderCard(props) {   
	console.log(props.link);
	if(!props.right){ 
    return (      
		<View
		style={[styles.block, Schemes["Color" + props.scheme].backgroundColor, , Schemes["Color" + props.scheme].borderColor]}>
		<View
			style={[styles.blockRound, styles.blockRoundLeft, Schemes["Color" + props.scheme].roundColor]}>
			<Image
			source={props.image}
			style={props.full ? styles.roundIcon : styles.roundIconHalf}/>
		</View>						
		<View
			pointerEvents="box-none"
			style={[styles.content, styles.contentRight]}>
			<Text
				style={[styles.blockHeading, Schemes["Color" + props.scheme].headingColor]}>{props.heading}</Text>
			<Text
				style={[styles.blockDescription, styles.textRight, Schemes["Color" + props.scheme].headingColor]}>{props.description}</Text>
			<View
				style={{
					flex: 1,
				}}/>
			<Text
				style={[styles.blockAction, Schemes["Color" + props.scheme].actionColor] }>{props.action}</Text>
		</View> 
	</View>       
    )} else {
	 return(
	<View
		style={[styles.block, Schemes["Color" + props.scheme].backgroundColor, , Schemes["Color" + props.scheme].borderColor]}>
		<View
			pointerEvents="box-none"
			style={[styles.content, styles.contentLeft]}>
			<Text
				style={[styles.blockHeading, Schemes["Color" + props.scheme].headingColor]}>{props.heading}</Text>
			<Text
				style={[styles.blockDescription, Schemes["Color" + props.scheme].headingColor]}>{props.description}</Text>
			<View
				style={{
					flex: 1,
				}}/>
			<Text
				style={[styles.blockAction, Schemes["Color" + props.scheme].actionColor] }>{props.action}</Text>
		</View> 
		<View
			style={[styles.blockRound, styles.blockRoundRight, Schemes["Color" + props.scheme].roundColor]}>
			<Image
			source={props.image}
			style={props.full ? styles.roundIcon : styles.roundIconHalf}/>
		</View>	
	</View> 
	 )
	}
  }

  return (
    <TouchableScale
        activeScale={0.95}
        tension={0}
        onPress={() => (props.link && props.link !== 'undefined') ? (props.link.includes('tel:') ? Linking.openURL(props.link)  :  navigation.navigate(props.link,props.params)) : null}
    >
       {renderCard(props)}	
    </TouchableScale>
  );
}

const styles = StyleSheet.create({
	block: {		
		borderWidth: 1,
		borderRadius: 8,
		height: 111,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.18,
		shadowRadius: 2.00,
		elevation: 2,
	},
	blockHeading: {
		fontFamily: "SourceSansPro-Bold",
		fontSize: 18,
		fontStyle: "normal",
		fontWeight: "bold",
		letterSpacing: 0.51,
	},
	blockDescription: {
		fontFamily: "Lato-Regular",
		fontSize: 12,
		fontStyle: "normal",
		fontWeight: "normal",
		letterSpacing: 0.34,
		width: 205,
		marginTop: 3,
	},
	blockAction: {
		fontFamily: "SourceSansPro-Black",
		fontSize: 14,
		fontStyle: "normal",
		fontWeight: "bold",		
		letterSpacing: 0.39,
		textTransform: "uppercase",
	},
	blockRound:{
		borderRadius: 999,
		width: 80,
		height: 80,
		top: 14,
		justifyContent: 'center',
	},
	blockRoundLeft:{
		left: 18,
	},
	blockRoundRight:{
		right: 18,
		alignSelf: "flex-end",
	},
	content:{
		position: "absolute",		
		top: 12,
		bottom: 11,		
	},
	contentLeft:{
		left: 18,
		alignItems: "flex-start",
	},
	contentRight:{		
		right: 18,	
		alignItems: "flex-end",
	},
	textLeft:{
		textAlign: "left",
	},
	textRight:{
		textAlign: "right",
	},
	roundIcon:{
		width: 80,
		height: 80,
    	alignSelf: 'center',
	},
	roundIconHalf:{
		width: 40,
		height: 40,
    	alignSelf: 'center',
	},
})