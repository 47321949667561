import * as React from 'react';
import {ScrollView, TouchableHighlight, Text, View, StyleSheet} from 'react-native';
import { useNavigation } from '@react-navigation/native';

export default function WebViewScreen() {
    const navigation = useNavigation();
    return (<View style={styles.container}>
                <iframe src="https://covid19.infermedica.com/en" height="92%" frameBorder="0"></iframe>
                <View style={styles.footerContainer}>
                        <TouchableHighlight style={styles.buttonContainer}  onPress={() => navigation.navigate('Home')}>
                            <Text style={styles.button}>Back to Home</Text>
                        </TouchableHighlight>
                </View>
           </View>
    );
}


const styles = StyleSheet.create({
	container: {
        flex: 1,
    },
    footerContainer:{
        height: '8%', 
        position: 'fixed', 
        bottom: 0, 
        backgroundColor: '#000', 
        width: '100%'
    },
    buttonContainer:{
        height: '100%', 
        justifyContent: 'center'
    },
	button: {
        color: '#fff',
        fontFamily: "SourceSansPro-Bold",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: "bold",
        textAlign: 'center',
	},
})

