import React from 'react';

import { StyleSheet, ScrollView, View, Text, Image, Dimensions } from "react-native";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import YouTube from 'react-youtube';

import client from '../utils/contentful';


export default class TipScreen extends React.Component {
	

	static navigationOptions = ({ navigation }) => {
	
		const { params = {} } = navigation.state
		return {
				header: null,
				headerLeft: null,
				headerRight: null,
			}
	}

	state = {
		data: []
	}

	componentDidMount() {	
        
       		
			client.getEntry(this.props.route.params.id)
             .then((entry) => {
				 this.setState({data: entry.fields});
				 console.log(entry.fields);
			  })
			 .catch(console.error)
		
	
	}

	

	render() {

		let youtubeRender = null;

		if(this.state.data.video) {
			let video = this.state.data.video
			const videoURL = video.replace("https://www.youtube.com/watch?v=","")
			youtubeRender = <YouTube
			videoId={videoURL} // The YouTube video ID
			opts={ytopts} // control playback of video with true/false					
			/>
		}

		return (				
			  <ScrollView
			  style={styles.container}>
				{youtubeRender}
				<View style={{
        			margin: 22}}
				>
				 {documentToReactComponents(this.state.data.content, options)}
				</View>                
              </ScrollView>
		  );
	}
}

const options = {
	renderMark: {
	  [BLOCKS.HEADING_3]: (text) => <Text style={[styles.blockDescription, styles.blockTitle]}>{text}</Text>,
	},
	renderNode: {
	  [BLOCKS.PARAGRAPH]: (node, children) => <Text style={styles.blockDescription}>{children}</Text>,
	},
};

const ytopts = {
	width: Dimensions.get('screen').width,
	height: Dimensions.get('screen').width * 0.5625,
	playerVars: {
        // https://developers.google.com/youtube/player_parameters
		autoplay: 0,
		modestbranding: 1,
		rel: 0,
		showInfo: 0,
		fs: 1,
    },
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: "#fff",
        flex: 1,
	},
	headerText: {
		backgroundColor: "transparent",
		color: "white",
		fontFamily: "SourceSansPro-Bold",
		fontSize: 20,
		fontStyle: "normal",
		fontWeight: "bold",
		textAlign: "left",
		letterSpacing: 0.56,
		position: "absolute",
		left: "5%",
		top: "45%",
	},
	blockHeading: {
		fontFamily: "SourceSansPro-Bold",
		fontSize: 18,
		fontStyle: "normal",
		fontWeight: "bold",
		letterSpacing: 0.51,
	},
	blockTitle: {		
		fontWeight: "bold",
		letterSpacing: 0.51,
	},
	blockDescription: {
		fontFamily: "Lato-Regular",
		fontSize: 12,
		fontStyle: "normal",
		fontWeight: "normal",
		letterSpacing: 0.34,
		marginTop: 3,
	},
})


