import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import * as React from 'react';

import { StyleSheet, Image, Text, View } from "react-native";

import HomeScreen from '../screens/HomeScreen';
import ProtectYourselfScreen from '../screens/ProtectYourselfScreen';
import HelplinesScreen from '../screens/HelplinesScreen';
import TipsListScreen from '../screens/TipsListScreen';
import CallListScreen from '../screens/CallListScreen';
import WebViewScreen from '../screens/WebViewScreen';
import TipScreen from '../screens/TipScreen';

const Stack = createStackNavigator();
const INITIAL_ROUTE_NAME = 'Home';

export default function ScreenNavigator() {
  // Set the header title on the parent stack navigator depending on the
  // currently active tab. Learn more in the documentation:
  // https://reactnavigation.org/docs/en/screen-options-resolution.html
  //navigation.setOptions({ headerTitle: getHeaderTitle(route) });
  function LogoTitle(props) {    
    return (      
     <View>        
          <Text style={styles.headerText}>{getHeaderTitle(props.name)}</Text>           
         {/* <View style={{alignSelf: 'flex-end'}}>
         <Image
            style={{ width: 100, height: 100}}
            source={require('../assets/images/virus.svg')}
        />
         </View> */}
     </View>      
    );
  }

  function Header(){
    return (
      <View style={{flex: 1, flexDirection: 'row'}}>
        <View style={{flex: 1}}>
          <Text>4 Views 0 Comments</Text>
        </View>
        <View style={{flex: 1}}>
          <Text style={{textAlign: 'right'}}>Solve This</Text>
        </View>
      </View>
    )
  }

  return (
    <Stack.Navigator
    screenOptions={{
        headerStyle: styles.bigHeader,
        headerTintColor: "#fff",
        headerTitleStyle: styles.headerText,
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
        gestureEnabled: false,
        headerLeft: null,
      }}
    
    initialRouteName={INITIAL_ROUTE_NAME}>
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{ headerTitle: <LogoTitle name="Home" /> }}       
      />
      <Stack.Screen
        name="ProtectYourself"
        component={ProtectYourselfScreen}
        options={{ headerTitle: <LogoTitle name="ProtectYourself" /> }}        
      />
      <Stack.Screen
        name="Helplines"
        component={HelplinesScreen}
        options={{ headerTitle: <LogoTitle name="Helplines" /> }}        
      />
      <Stack.Screen
        name="Tips"
        component={TipsListScreen}
        options={{ headerTitle: <LogoTitle name="Tips" /> }}        
      />
      <Stack.Screen
        name="Call"
        component={CallListScreen}
        options={{ headerTitle: <LogoTitle name="Call" /> }}        
      />
      <Stack.Screen
        name="Web"
        component={WebViewScreen}
        options={{headerShown: false}}        
      />
      <Stack.Screen
        name="Tip"
        component={TipScreen}
        options={{headerShown: false}}        
      />
    </Stack.Navigator>
  );
}

function getHeaderTitle(route) {
  const routeName = route;

  switch (routeName) {
    case 'Home':
      return 'Help Center';
    case 'ProtectYourself':
        return 'Protect Yourself';
    case 'Helplines':
      return 'Helplines';
    case 'Tips':
        return 'Tips';
    case 'Call':
      return 'Feeling Sick?';
    case 'Web':
      return 'Assessment Tool';
    case 'Tip':
      return 'Tip';
    default:
        return 'Help Center';
  }
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: "#fff",
		alignSelf: 'flex-end',
	},
	bigHeader: {
		backgroundColor: "#101536",		
    height: "20vh",
	},
	headerText: {
		backgroundColor: "transparent",
		color: "white",
		fontFamily: "SourceSansPro-Bold",
		fontSize: 20,
		fontStyle: "normal",
		fontWeight: "bold",
		letterSpacing: 0.56,
    textAlign: 'center',
    alignSelf: 'center'
	},
})
