//
//  CoronaHelpCenterIntro
//  Corona Help Center
//
//  Created by [Author].
//  Copyright © 2018 [Company]. All rights reserved.
//

import React from "react"
import { Image, StyleSheet, Text, View, Dimensions, Button } from "react-native";

import { FlatList } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import FullCard from '../components/FullCard';
import client from '../utils/contentful';

import SkeletonFull from '../components/SkeletonFull';

export default class HomeScreen extends React.Component {

	state = {
		data: [],
		isLoading: true,
	}

	componentDidMount() {
		
			this.props.navigation.setOptions({ headerTitle: this.props.route.params.tipCategory })
		
			client.getEntries({
				content_type: 'tip',			
				order: 'fields.sortOrder',
				'fields.category.sys.contentType.sys.id': 'tipCategory',
				'fields.category.fields.title': this.props.route.params.tipCategory,
				'fields.enabled' : true,	
			  })
			  .then((response) => this.setState({data: response.items, isLoading: false}))
			  .catch(console.error)
		
	
	}

	render() {



		const {data, isLoading} = this.state;
		

		if(isLoading){
			return(				
					<SkeletonFull isLoading/>
			)
		}else{
			return (			
							
			  <FlatList
			  style={styles.container}
				data={data}
				keyExtractor={item => item.sys.id}
				horizontal={false}
				renderItem={({ item, index }) => (
				  <View style={{marginLeft: 22, marginRight: 22, marginTop: 11, marginBottom: 11 }}
					>
					<FullCard 
						scheme={index%6 + 1}
						heading={item.fields.title} 
						description={item.fields.description}
						action={item.fields.video ? 'Watch Video' : 'Read Article'}
						image={item.fields.video ? require('../assets/images/play.svg') : require('../assets/images/article.svg')}
						link="Tip"
						params={{id : item.sys.id}} />						
				  </View>
				)}
			  />
			
		  );
		}
	}
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: "#fff",
		flex: 1,
		paddingTop: 30,
	},
	headerText: {
		backgroundColor: "transparent",
		color: "white",
		fontFamily: "SourceSansPro-Bold",
		fontSize: 20,
		fontStyle: "normal",
		fontWeight: "bold",
		textAlign: "left",
		letterSpacing: 0.56,
		position: "absolute",
		left: "5%",
		top: "45%",
	},
})
