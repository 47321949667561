import * as React from 'react';
import { Image, StyleSheet, Text, View } from "react-native";
import TouchableScale from 'react-native-touchable-scale';
import * as Schemes from '../constants/ColorSchemes';
import { useNavigation } from '@react-navigation/native';

export default function FullCard(props) {

  const navigation = useNavigation();

  return (
    <TouchableScale
        activeScale={0.95}
        tension={0}
        onPress={() => navigation.navigate(props.link, props.params)}
    >
        <View
            style={[styles.block, Schemes["Color" + props.scheme].backgroundColor, Schemes["Color" + props.scheme].borderColor]}>
            <View
                style={styles.blockRound}>
                <Image
                source={props.image}
                style={styles.roundIcon}/>
            </View>
                <Text
                    style={[styles.blockHeading, Schemes["Color" + props.scheme].headingColor]}>{props.heading}</Text> 
        </View>				
    </TouchableScale>
  );
}

const styles = StyleSheet.create({	
	block: {		
		borderWidth: 1,
		borderRadius: 8,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.18,
		shadowRadius: 2.00,
		elevation: 2,
	},
	blockHeading: {
		fontFamily: "SourceSansPro-Bold",
		fontSize: 16,
		fontStyle: "normal",
		fontWeight: "bold",
		letterSpacing: 0.51,
		textAlign: "center",
		marginBottom: 15,
	},
	blockRound:{		
		borderRadius: 999,
		width: 80,
		height: 80,
		margin: 20,
		alignSelf: "center"
	},
	roundIcon:{
		height: 80,
	},
})