export const Color1 = {
    backgroundColor: {
        backgroundColor: '#FFECEC'
    },
    headingColor: {
        color: '#C01D1D'
    },
    actionColor: {
        color:'#DA4E1B'
    },
    roundColor: {
        backgroundColor: '#E86E6E'
    },
    darkColor: {
        backgroundColor: '#FFECEC'
    },
    borderColor: {
        borderColor: "#FFAEAE",
    }
};

export const Color2 = {
    backgroundColor: {
        backgroundColor: '#FEF9F5'
    },
    headingColor: {
        color: '#67332A'
    },
    actionColor: {
        color:'#D76C45'
    },
    roundColor: {
        backgroundColor: '#E29679'
    },
    darkColor: {
        backgroundColor: '#FEF9F5'
    },
    borderColor: {
        borderColor: "#EDC5A4",
    }
};

export const Color3 = {
    backgroundColor: {
        backgroundColor: '#F6FEFF'
    },
    headingColor: {
        color: '#374D54'
    },
    actionColor: {
        color:'#1B9892'
    },
    roundColor: {
        backgroundColor: '#72C0BE'
    },
    darkColor: {
        backgroundColor: '#F6FEFF'
    },
    borderColor: {
        borderColor: "#93E6E2",
    }
};
export const Color4 = {
    backgroundColor: {
        backgroundColor: '#FFECF2'
    },
    headingColor: {
        color: '#603C4D'
    },
    actionColor: {
        color:'#B4456F'
    },
    roundColor: {
        backgroundColor: '#D388A3'
    },
    darkColor: {
        backgroundColor: '#603C4D'
    },
    borderColor: {
        borderColor: "#FFAECD",
    }
};


export const Color5 = {
    backgroundColor: {
        backgroundColor: '#ECF6FF'
    },
    headingColor: {
        color: '#3C4E60'
    },
    actionColor: {
        color:'#4548B4'
    },
    roundColor: {
        backgroundColor: '#8E8CD3'
    },
    darkColor: {
        backgroundColor: '#3C4E60'
    },
    borderColor: {
        borderColor: "#AEB6FF",
    }
};

export const Color6 = {
    backgroundColor: {
        backgroundColor: '#F3FFEC'
    },
    headingColor: {
        color: '#37543C'
    },
    actionColor: {
        color:'#45AE58'
    },
    roundColor: {
        backgroundColor: '#7BC087'
    },
    darkColor: {
        backgroundColor: '#603C4D'
    },
    borderColor: {
        borderColor: "#AEFFD7",
    }
};