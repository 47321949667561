import * as React from 'react';

import { StyleSheet, Text, View } from "react-native";

import SkeletonContent from "react-native-skeleton-content";

export default function SkeletonFull(props){    


    function loopCard(length, right){  
        
        let cards = [];

        if(!right){
            for (let i = 0; i < length; i++) {
                cards.push(
                    <View key={i+'-SkelFul'} style={{marginLeft: 22, marginRight: 22, marginTop: 11, marginBottom: 11 }}>
                        <View
                        style={styles.block}>
                            <SkeletonContent
                                    containerStyle={{flex: 1}}
                                    isLoading={props.isLoading}
                                    layout={leftLayout}
                                    >						
                                <View>Image</View>	
                                <Text>Heading</Text>                  
                                <Text>Action</Text>                						
                            </SkeletonContent>
                        </View>             
                </View>
                )
            }    
        }else{
            for (let i = 0; i < length; i++) {
                cards.push(
                    <View style={{marginLeft: 22, marginRight: 22, marginTop: 11, marginBottom: 11 }}>
                        <View
                        style={styles.block}>
                            <SkeletonContent
                                    containerStyle={{flex: 1}}
                                    isLoading={props.isLoading}
                                    layout={rightLayout}
                                    >
                                <Text>Heading</Text>                  
                                <Text>Action</Text>						
                                <View>Image</View>					
                            </SkeletonContent>
                        </View>             
                </View>
                )
            }
        }

       
        return cards;
    }

    return(
        <View style={styles.container}>
            {loopCard(props.length ? props.length : 3, props.right)}             
        </View> 
    )
}

const leftLayout = [
    {
      width: 80,
      height: 80,
      borderRadius: 40,
      top: 14,
      left: 18,
    },
    {
      width: 140,
      height: 18,
      right: 18,
      position: "absolute",		
          top: 12,
      alignItems: "flex-end",
    },
    {
      width: 90,
      height: 14,
      right: 18,
      position: "absolute",		
          bottom: 12,
      alignItems: "flex-end",
    },
  ];

  const rightLayout = [
    {
      width: 80,
      height: 80,
      borderRadius: 40,
      top: 14,
      right: 18,
      position: "absolute",
      alignItems: "flex-end",
    },
    {
      width: 140,
      height: 18,
      left: 18,
      position: "absolute",		
      top: 12,
      alignItems: "flex-start",
    },
    {
      width: 90,
      height: 14,
      left: 18,
      position: "absolute",		
      bottom: 12,
      alignItems: "flex-start",
    },
  ];

const styles = StyleSheet.create({	
    container: {
		backgroundColor: "#fff",
		flex: 1,
		paddingTop: 30,
	},
	block: {		
		borderRadius: 8,
		height: 111,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.18,
		shadowRadius: 2.00,
		elevation: 2,
	},
})