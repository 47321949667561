//
//  CoronaHelpCenterIntro
//  Corona Help Center
//
//  Created by [Author].
//  Copyright © 2018 [Company]. All rights reserved.
//

import React from "react"
import { Image, StyleSheet, Text, View, Dimensions } from "react-native";

import { FlatList } from 'react-native-gesture-handler';
import HalfCard from '../components/HalfCard';
import client from '../utils/contentful';

import SkeletonHalf from '../components/SkeletonHalf';

export default class HomeScreen extends React.Component {
	

	static navigationOptions = ({ navigation }) => {
	
		const { params = {} } = navigation.state
		return {
				header: null,
				headerLeft: null,
				headerRight: null,
			}
	}

	state = {
		data: [],
		isLoading: true
	}


	componentDidMount() {

		client.getEntries({
			content_type: 'tipCategory',
			order: 'fields.sortOrder',
			'fields.enabled' : true

		  })
		  .then((response) => this.setState({data: response.items, isLoading: false}))
		  .catch(console.error)
	
	}


	render() {
	
				let width = Dimensions.get('screen').width/2 - 33;

				const {data,isLoading} = this.state;


				if(isLoading){
					return(				
							<SkeletonHalf isLoading width={width}/>
					)
				}else{
					return (					
							<FlatList
							style={styles.container}
								columnWrapperStyle={{justifyContent:'space-between'}}
								data={data}
								keyExtractor={item => item.sys.id}
								horizontal={false}
								numColumns={2}
								renderItem={({ item, index }) => (
								<View style={ (index+1)%2 ? 
									{width:width, marginLeft: 22, marginRight: 11, marginTop: 11, marginBottom: 11 } :
									{width:width, marginLeft: 11, marginRight: 22, marginTop: 11, marginBottom: 11}}
									>
									<HalfCard 
										scheme={index%6 + 1}
										heading={item.fields.title}
										image={item.fields.image.fields.file.url}
										link={item.fields.link}
										params={{tipCategory : item.fields.title}} />
								</View>
								)}
							/>
						);
				}
		   
	}
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: "#fff",
		flex: 1,
		paddingTop: 30,
	},
	headerText: {
		backgroundColor: "transparent",
		color: "white",
		fontFamily: "SourceSansPro-Bold",
		fontSize: 20,
		fontStyle: "normal",
		fontWeight: "bold",
		textAlign: "left",
		letterSpacing: 0.56,
		position: "absolute",
		left: "5%",
		top: "45%",
	},
})
