import * as React from 'react';

import { StyleSheet, Text, View } from "react-native";

import SkeletonContent from "react-native-skeleton-content";

export default function SkeletonHalf(props){    


    function loopCard(length){  
        
        let cards = [];
        
            for (let i = 0; i < length; i++) {
                cards.push(                    
                    <View key={i+'-SkelHalf'} style={ (i+1)% 2 ? 
                        {marginLeft: 22, marginRight: 11, marginTop: 11, marginBottom: 11, width: props.width} :
                        {marginLeft: 11, marginRight: 22, marginTop: 11, marginBottom: 11, width: props.width}}
                        >
                            <View
                            style={styles.block}>
                                <SkeletonContent                                       
                                        isLoading={props.isLoading}
                                        layout={layout}
                                        >						
                                    <View>Image</View>	
                                    <Text>Heading</Text>               						
                                </SkeletonContent>
                            </View>             
                    </View>
                )
            }

       
        return cards;
    }

    return(
        <View style={styles.container}>
            <View style={styles.cardContainer}>
                {loopCard(props.length ? props.length : 4)} 
            </View>                        
        </View> 
    )
}

const layout = [
    {
      width: 80,
      height: 80,
      borderRadius: 40,
      margin: 20,
	  alignSelf: "center"
    },
    {
      width: '60%',
      height: 16,
      alignSelf: "center",
	  marginBottom: 15,
    },
  ];

const styles = StyleSheet.create({	
    container: {
		backgroundColor: "#fff",
        flex: 1,
        paddingTop: 30,
    },
    cardContainer:{
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
	block: {		
		borderRadius: 8,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.18,
		shadowRadius: 2.00,
		elevation: 2,
	},
})