import { useLinking } from '@react-navigation/native';
import { AsyncStorage } from 'react-native';
import { Linking } from 'expo';

export default function(containerRef) {
  Linking.getInitialURL().then((url) => {
    if (url) {
      let newURL = Linking.parse(url);
      AsyncStorage.setItem('location', newURL.queryParams.location).
      then();
    }
  }).catch(err => console.error('An error occurred', err));
  return useLinking(containerRef, {
    prefixes: [Linking.makeUrl('/')],
    config: {
      Root: {
        path: 'root',
        screens: {
          Home: 'home',
        },
      },
    },
  });
}
