//
//  CoronaHelpCenterIntro
//  Corona Help Center
//
//  Created by [Author].
//  Copyright © 2018 [Company]. All rights reserved.
//

import React from "react"
import { Image, StyleSheet, Text, View } from "react-native";
import { FlatList } from 'react-native-gesture-handler';
import FullCard from '../components/FullCard';
import client from '../utils/contentful';

import SkeletonFull from '../components/SkeletonFull';


export default class HomeScreen extends React.Component {
	

	state = {
		data: [],
		isLoading: true,
	}

	componentDidMount() {

		if( typeof this.props.route.params !== 'undefined' && this.props.route.params !== null && this.props.route.params.helplineCategory ){
			this.props.navigation.setOptions({ headerTitle: this.props.route.params.helplineCategory })
			client.getEntries({
				content_type: 'helpline',
				order: 'fields.sortOrder',
				'fields.category.sys.contentType.sys.id': 'helplineCategory',
				'fields.category.fields.title': this.props.route.params.helplineCategory,
				'fields.enabled' : true
	
			  })
			  .then((response) => this.setState({data: response.items, isLoading: false}))
			  .catch(console.error)
		}else if(typeof this.props.route.params !== 'undefined' && this.props.route.params !== null && this.props.route.params.location){
			client.getEntries({
				content_type: 'medicalCenter',
				'fields.location[within]': this.props.route.params.location + ',5',
				'fields.enabled' : true
	
			  })
			  .then((response) =>this.setState({data: response.items, isLoading: false}))
			  .catch(console.error)
		}
	
	}

	renderFacilties(facilitiesArray){
		let facilities = '';

		facilitiesArray.map(el=>{
			facilities += el.fields ? el.fields.title + ', ' : '' ;
		})

		return facilities.substring(0, facilities.length - 2);
	}

	render() {

		const {data, isLoading} = this.state;

		if(isLoading){
			return(				
					<SkeletonFull isLoading right/>
			)
		}else{
			return (				
			  <FlatList
			  style={styles.container}
				data={data}
				keyExtractor={item => item.sys.id}
				horizontal={false}
				renderItem={({ item, index }) => (
				  <View style={{marginLeft: 22, marginRight: 22, marginTop: 11, marginBottom: 11 }}
					>
					<FullCard 
						scheme={index%6 + 1}
						heading={item.fields.title} 
						description={item.fields.facilities ? this.renderFacilties(item.fields.facilities) : item.fields.description}
						action="Call Us"
						image={require("../assets/images/phone.svg")}
						link={'tel:' + item.fields.phone}
						right={true} />						
				  </View>
				)}
			  />
		  );
		}
	}
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: "#fff",
		flex: 1,
		paddingTop: 30,
	},
	headerText: {
		backgroundColor: "transparent",
		color: "white",
		fontFamily: "SourceSansPro-Bold",
		fontSize: 20,
		fontStyle: "normal",
		fontWeight: "bold",
		textAlign: "left",
		letterSpacing: 0.56,
		position: "absolute",
		left: "5%",
		top: "45%",
	},
})
