import * as React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import * as Font from 'expo-font';
import { NavigationContainer } from '@react-navigation/native';
import SkeletonFull from './components/SkeletonFull';

import ScreenNavigator from './navigation/ScreenNavigator';
import useLinking from './navigation/useLinking';

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const [initialNavigationState, setInitialNavigationState] = React.useState();
  const containerRef = React.useRef();
  const { getInitialState } = useLinking(containerRef);

  const firstLayout = [
    {
      width: 80,
      height: 80,
      borderRadius: 40,
      top: 14,
      left: 18,
    },
    {
      width: 140,
      height: 18,
      right: 18,
      position: "absolute",		
		  top: 12,
      alignItems: "flex-end",
    },
    {
      width: 90,
      height: 14,
      right: 18,
      position: "absolute",		
		  bottom: 12,
      alignItems: "flex-end",
    },
  ];

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {

        // Load our initial navigation state
        setInitialNavigationState(await getInitialState());

        // Load fonts
        await Font.loadAsync({
          "Lato-Regular": require("./assets/fonts/LatoRegular.ttf"),
			    "SourceSansPro-Black": require("./assets/fonts/SourceSansProBlack.otf"),
			    "SourceSansPro-Bold": require("./assets/fonts/SourceSansProBold.otf"),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return (

      <View style={styles.container}>
        <View style={styles.bigHeader} />
        <SkeletonFull isLoading={!isLoadingComplete} />
      </View>      
    );
  } else {
    return (
      <View style={styles.container}>
        <NavigationContainer ref={containerRef} initialState={initialNavigationState}>
          <ScreenNavigator />
        </NavigationContainer>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  bigHeader: {
		backgroundColor: "#101536",		
    height: "20vh",
    justifyContent: 'center',
	},
	headerText: {
		backgroundColor: "transparent",
		color: "white",
		fontFamily: "SourceSansPro-Bold",
		fontSize: 20,
		fontStyle: "normal",
		fontWeight: "bold",
		letterSpacing: 0.56,
  },
});
